import axios from "axios";

const getIndividualPrices = async (
  token,
  roomNumber,
  fromCountryId,
  toCountryId,
  fromDate,
  toDate,
  price,
  transportationTypeId,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    roomNumber: roomNumber,
    fromCountryId: fromCountryId,
    toCountryId: toCountryId,
    fromDate: fromDate,
    toDate: toDate,
    price: price,
    transportationTypeId: transportationTypeId,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel/get_user_prices`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const addIndividualPrice = async (individualPriceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel/add_user_price`,
    individualPriceData,
    config
  );

  return response.data;
};

const editIndividualPrice = async (individualPriceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel/change_user_price`,
    individualPriceData,
    config
  );

  return response.data;
};

const deleteIndividualPrice = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel/delete_user_price`,
    id,
    config
  );

  return response.data;
};

const individualPricesService = {
  addIndividualPrice,
  editIndividualPrice,
  deleteIndividualPrice,
  getIndividualPrices,
};
export default individualPricesService;
