import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import menuReducer from "../features/menu/MenuSlice";
// import projectsReducer from "../features/projects/ProjectsSlice";
import clientsReducer from "../features/clients/ClientsSlice";
import countriesReducer from "../features/countries/CountriesSlice";
import fileCategoriesReducer from "../features/filecategories/FileCategoriesSlice";
import departmentsReducer from "../features/departments/DepartamentsSlice";
import employeesReducer from "../features/employees/EmployeesSlice";
import rolesReducer from "../features/roles/RolesSlice";
import departmentLinksReducer from "../features/departmentLinks/DepartmentLinksSlice";
import categoriesReducer from "../features/categories/CategoriesSlice";
import groupsReducer from "../features/groups/GroupsSlice";
import roomUsersReducer from "../features/groups/UsersSlice";
import trackingcodeReducer from "../features/groups/trackingCodeSlice";

//dashboard reducers
import periodsReducer from "../features/dashboard/periods/PeriodsSlice";
import shelfParcelsReducer from "../features/dashboard/shelfParcels/ShelfParcelsSlice";
import issuedParcelsReducer from "../features/dashboard/issuedParcels/IssuedParcelsSlice";
import racesProgressReducer from "../features/dashboard/racesProgress/RacesProgressSlice";
import deliveryTimeReducer from "../features/dashboard/deliveryTime/DeliveryTimeSlice";
import responseTimeReducer from "../features/dashboard/responseTime/ResponseTimeSlice";
import customerScoreReducer from "../features/dashboard/customerScore/CustomerScoreSlice";
import productsReducer from "../features/dashboard/products/productsSlice";
import branchesReducer from "../features/dashboard/branches/BranchesSlice";

// import thunk from "redux-thunk";
//notifications page reducers
import notificationTypesReducer from "../features/notifications/notTypes/NotTypeSlice";
import notificationCustomerTypesReducer from "../features/notifications/notCustomerTypes/notCustomerTypesSlice";
import notificationParcelGroupsReducer from "../features/notifications/parcelGroups/ParcelGroupsSlice";
import notificationsReducer from "../features/notifications/notifications/NotificationsSlice";
import notificationStatusesReducer from "../features/notifications/statuses/StatusesSlice";
import recipientTypesReducer from "../features/notifications/recipientTypes/RecipientTypesSlice";
import notificationFileReducer from "../features/notifications/files/FilesSlice";

//prices reducers
import countriesReducerForPrice from "../features/prices/getCountries/GetCountriesSlice";
import userTypesReducerForPrice from "../features/prices/getUserTypes/GetUserTypesSlice";
import transportationTypesForPrice from "../features/prices/getTransportationTypes/GetTransportationTypesSlice";
import systemPricesReducer from "../features/prices/systemPrice/SystemPriceSlice";
import individualPricesReducer from "../features/prices/individualPrices/IndividualPricesSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    // projects: projectsReducer,
    clients: clientsReducer,
    countries: countriesReducer,
    fileCategories: fileCategoriesReducer,
    departments: departmentsReducer,
    employees: employeesReducer,
    roles: rolesReducer,
    departmentLinks: departmentLinksReducer,
    categories: categoriesReducer,
    groups: groupsReducer,

    //dashboard reducers
    periods: periodsReducer,
    shelfParcels: shelfParcelsReducer,
    issuedParcels: issuedParcelsReducer,
    racesProgress: racesProgressReducer,
    deliveryTime: deliveryTimeReducer,
    responseTime: responseTimeReducer,
    score: customerScoreReducer,
    dashboardProducts: productsReducer,
    branches: branchesReducer,
    roomUsers: roomUsersReducer,
    trackingCode: trackingcodeReducer,
    notificationTypes: notificationTypesReducer,
    notificationCustomerTypes: notificationCustomerTypesReducer,
    notificationsParcelGroups: notificationParcelGroupsReducer,
    notificationsList: notificationsReducer,
    notificationStatuses: notificationStatusesReducer,
    recipientTypes: recipientTypesReducer,
    notificationFile: notificationFileReducer,
    countriesForPrice: countriesReducerForPrice,
    userTypesForPrice: userTypesReducerForPrice,
    transportationTypesForPrice: transportationTypesForPrice,
    systemPrices: systemPricesReducer,
    individualPrices: individualPricesReducer,
  },

  // middleware: [...getDefaultMiddleware(), thunk],
});

export default store;
