import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { editIndividualPrice } from "../../../features/prices/individualPrices/IndividualPricesSlice";
import ModalsHeadline from "../components/ModalsHeadline";
import ButtonDefault from "../../buttons/ButtonDefault";
import Select from "../standartPrices/Select";
import Input from "../../common/Input";
import SingleDatePicker from "./SingleDatePicker";

const EditUserPrices = ({
  setMessage,
  setSeverity,
  setShowResponseMessage,
  setShowEditPrice,
  editPriceId,
  setRefetch,
  refetch,
}) => {
  const [formData, setFormData] = useState({
    fromCountryId: "",
    toCountryId: "",
    fromDate: "",
    toDate: "",
    price: "",
    transportationTypeId: "",
  });
  const [errors, setErrors] = useState({
    fromCountryId: "",
    toCountryId: "",
    fromDate: "",
    toDate: "",
    price: "",
    transportationTypeId: "",
  });
  const [roomNumber, setRoomNumber] = useState("");
  const { t } = useTranslation();
  const { countries } = useSelector((state) => state.countriesForPrice);
  const { transportationTypes } = useSelector(
    (state) => state.transportationTypesForPrice
  );
  const { individualPrices } = useSelector((state) => state.individualPrices);
  const dispatch = useDispatch();

  const {
    fromCountryId,
    toCountryId,
    fromDate,
    toDate,
    price,
    transportationTypeId,
  } = formData;

  useEffect(() => {
    if (editPriceId) {
      const price = individualPrices?.userPrices?.find(
        (item) => item.id === editPriceId
      );

      if (individualPrices?.userPrices && editPriceId && price) {
        setFormData((prev) => ({
          ...prev,
          fromCountryId: price.fromCountryId,
          toCountryId: price.toCountryId,
          fromDate: price.fromDate,
          toDate: price.toDate,
          price: price.price,
          transportationTypeId: price.transportationTypeId,
        }));
        setRoomNumber(price.roomNumber);
      }
    }
  }, [editPriceId, individualPrices?.userPrices]);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formattDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (
      !fromCountryId ||
      !toCountryId ||
      !fromDate ||
      !toDate ||
      !price ||
      !transportationTypeId
    ) {
      setErrors({
        fromCountryId: fromCountryId ? "" : "From Country is required",
        toCountryId: toCountryId ? "" : "To Country is required",
        fromDate: fromDate ? "" : "From Date is required",
        toDate: toDate ? "" : "To Date is required",
        price: price ? "" : "Price is required",
        transportationTypeId: transportationTypeId
          ? ""
          : "Transportation Type is required",
      });

      return;
    }

    const formData = {
      id: editPriceId,
      fromCountryId,
      toCountryId,
      price,
      transportationTypeId,
      fromDate: fromDate ? formattDate(fromDate) : "",
      toDate: toDate ? formattDate(toDate) : "",
    };

    try {
      const response = await dispatch(editIndividualPrice(formData));
      if (response.payload.type === "success") {
        setRefetch(!refetch);
        setFormData({
          userId: "",
          fromCountryId: "",
          toCountryId: "",
          fromDate: "",
          toDate: "",
          price: "",
          transportationTypeId: "",
        });
        setShowEditPrice(false);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("success");
      }

      if (response?.error?.message === "Rejected") {
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text="Edit User Price"
          onClick={() => setShowEditPrice(false)}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="w-full rounded-md overflow-hidden bg-white border border-gray-2 flex flex-col items-start py-2 px-4 opacity-55">
            <span className="text-sm text-gray-1 font-normal">User</span>
            <span className="text-sm text-gray-1 font-normal">
              {roomNumber}
            </span>
          </div>
          <div className="relative w-full">
            <Input
              text="Price"
              name="price"
              value={formData.price}
              onchange={handleInputChange}
              error={errors.price}
              errormark={errors.price ? "error-border" : ""}
            />
            {errors.price && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.price}
              </p>
            )}
          </div>
          <div className="relative">
            <Select
              options={countries
                ?.filter((cat) => cat.sendAllowed === "Y")
                .map((cat) => ({
                  value: cat.countryId,
                  label: t(cat.countryDictionaryKey),
                }))}
              text="From Country"
              name="fromCountryId"
              value={formData.fromCountryId}
              onChange={handleInputChange}
              error={errors.fromCountryId}
            />
            {errors.fromCountryId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.fromCountryId}
              </p>
            )}
          </div>
          <div className="relative">
            <Select
              options={countries
                ?.filter((cat) => cat.receivedAllowed === "Y")
                .map((cat) => ({
                  value: cat.countryId,
                  label: t(cat.countryDictionaryKey),
                }))}
              text="To Country"
              name="toCountryId"
              value={formData.toCountryId}
              onChange={handleInputChange}
              error={errors.toCountryId}
            />
            {errors.toCountryId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.toCountryId}
              </p>
            )}
          </div>
          <div className="relative">
            <Select
              options={transportationTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text="Transportation Type"
              name="transportationTypeId"
              value={formData.transportationTypeId}
              onChange={handleInputChange}
              error={errors.transportationTypeId}
            />
            {errors.transportationTypeId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.transportationTypeId}
              </p>
            )}
          </div>
          <div className="relative">
            <SingleDatePicker
              text="From Date"
              name="fromDate"
              value={formData.fromDate}
              onChange={handleInputChange}
              error={errors.fromDate}
              clear={!formData.fromDate}
            />
            {errors.fromDate && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.fromDate}
              </p>
            )}
          </div>
          <div className="relative">
            <SingleDatePicker
              text="To Date"
              name="toDate"
              value={formData.toDate}
              onChange={handleInputChange}
              error={errors.toDate}
              clear={!formData.toDate}
            />
            {errors.toDate && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.toDate}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault>{t("text.edit")}</ButtonDefault>
      </div>
    </form>
  );
};

export default EditUserPrices;
