import { BackSvg } from "../../icons";
import { Link } from "react-router-dom";

const PricesPropertiesBar = () => {
  return (
    <div className="w-full border-b border-gray-300 bg-gray-100 flex flex-col md:justify-between md:items-center">
      <div className="p-4 flex items-center justify-between w-full">
        <div className="flex items-center justify-start gap-3 w-full">
          <Link
            to="/MainPage"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          <div className="text-lg font-semibold text-gray-800 md:text-xl">
            User Prices
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricesPropertiesBar;
