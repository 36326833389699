import { useState } from "react";
import custom from "../../assets/custom-template.png";

const Templates = () => {
  const templates = [
    { id: 1, name: "Blank Template", image: custom },
    { id: 2, name: "Custom Template", image: custom },
  ];

  const [activeTemplateId, setActiveTemplateId] = useState(null);

  return (
    <div>
      <div className="grid grid-cols-2 gap-6">
        {templates.map((template) => (
          <button
            type="button"
            key={template.id}
            className="flex flex-col items-start justify-start gap-2"
            onClick={() => setActiveTemplateId(template.id)}
          >
            <div className="relative flex justify-center items-center rounded overflow-hidden">
              <img src={template.image} alt={template.name} />

              {activeTemplateId === template.id && (
                <div className="absolute inset-0 bg-blue-2/30 flex justify-center items-center"></div>
              )}
            </div>
            <span className="text-gray-1 text-sm text-start">
              {template.name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Templates;
