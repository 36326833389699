import { useState, useEffect } from "react";
import { BackSvg } from "../../components/icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  editNotification,
  getNotificationDetails,
} from "../../features/notifications/notifications/NotificationsSlice";
import { Alert, Snackbar } from "@mui/material";
import NotificationsLayout from "./NotificationsLayout";
import ContentTemplate from "../../components/notifications/ContentTemplate";
import TextArea from "../../components/notifications/TextArea";
import GroupUser from "../../components/notifications/GroupUser";
import Spinner from "../../components/common/Spinner";

const EditNotification = () => {
  const [font, setFont] = useState("Arial");
  const [language, setLanguage] = useState("GE");
  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [message, setMessage] = useState("");
  const { id } = useParams();
  const [formData, setFormData] = useState({
    notificationId: id,
    scheduleDate: "",
    notificationDetails: {
      GE: {
        title: "",
        message: "",
      },
      EN: {
        title: "",
        message: "",
      },
    },
    notificationTypes: [],
    notificationCustomerTypes: [],
    parcelGroupBarcodes: [],
    userRoomNumbers: [],
    emails: [],
    mobiles: [],
    onlyDeclared: "N",
  });
  const [errors, setErrors] = useState({
    notificationDetails: {
      GE: {
        title: "",
        message: "",
      },
      EN: {
        title: "",
        message: "",
      },
    },
    notificationTypes: [],
    notificationCustomerTypes: [],
  });

  const dispatch = useDispatch();
  const { isLoading, details } = useSelector(
    (state) => state.notificationsList
  );

  const {
    notificationId,
    scheduleDate,
    notificationDetails,
    notificationTypes,
    notificationCustomerTypes,
    parcelGroupBarcodes,
    userRoomNumbers,
    emails,
    mobiles,
    onlyDeclared,
  } = formData;

  useEffect(() => {
    dispatch(getNotificationDetails({ notificationId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (details) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        notificationId: id,
        scheduleDate: details.scheduleDate || "",
        notificationDetails: details.notificationDetails || {
          GE: { title: "", message: "" },
          EN: { title: "", message: "" },
        },
        notificationTypes: details.notificationTypes || [],
        notificationCustomerTypes: details.notificationCustomerTypes || [],
        parcelGroupBarcodes: details.parcelGroupBarcodes || [],
        userRoomNumbers: details.userRoomNumbers || [],
        emails: details.emails || [],
        mobiles: details.mobiles || [],
        onlyDeclared: details.onlyDeclared || "N",
      }));
    }
  }, [details, id]);

  const validateFields = () => {
    const newErrors = {
      notificationDetails: {
        GE: {
          title: "",
          message: "",
        },
        EN: {
          title: "",
          message: "",
        },
      },
      notificationTypes: [],
      notificationCustomerTypes: [],
    };

    if (!formData.notificationDetails.GE.title.trim()) {
      newErrors.notificationDetails.GE.title =
        "Please fill in the Georgian title.";
    }
    if (!formData.notificationDetails.GE.message.trim()) {
      newErrors.notificationDetails.GE.message =
        "Please fill in the Georgian message.";
    }

    if (!formData.notificationDetails.EN.title.trim()) {
      newErrors.notificationDetails.EN.title =
        "Please fill in the English title.";
    }
    if (!formData.notificationDetails.EN.message.trim()) {
      newErrors.notificationDetails.EN.message =
        "Please fill in the English message.";
    }

    if (formData.notificationTypes.length === 0) {
      newErrors.notificationTypes = [
        "Please select at least one notification type.",
      ];
    }

    if (formData.notificationCustomerTypes.length === 0) {
      newErrors.notificationCustomerTypes = [
        "Please select at least one customer type.",
      ];
    }

    setErrors(newErrors);

    return !(
      newErrors.notificationDetails.GE.title ||
      newErrors.notificationDetails.GE.message ||
      newErrors.notificationDetails.EN.title ||
      newErrors.notificationDetails.EN.message ||
      newErrors.notificationTypes.length > 0 ||
      newErrors.notificationCustomerTypes.length > 0
    );
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    const notificationData = {
      notificationId,
      scheduleDate,
      notificationDetails: JSON.stringify(notificationDetails),
      notificationTypes: JSON.stringify(notificationTypes),
      notificationCustomerTypes: JSON.stringify(notificationCustomerTypes),
      parcelGroupBarcodes: JSON.stringify(parcelGroupBarcodes),
      userRoomNumbers: JSON.stringify(userRoomNumbers),
      emails: JSON.stringify(emails),
      mobiles: JSON.stringify(mobiles),
      onlyDeclared,
    };

    try {
      const response = await dispatch(editNotification(notificationData));
      if (response.payload.type === "success") {
        setSeverity("success");
        setShowResponseMessage(true);
        setMessage(response.payload.message);
      }
      if (response.error.message === "Rejected") {
        setSeverity("error");
        setShowResponseMessage(true);
        setMessage(response.payload.message);
      }
    } catch (error) {}
  };

  return (
    <NotificationsLayout>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          {showResponseMessage && (
            <Snackbar
              open={showResponseMessage}
              autoHideDuration={severity === "error" ? null : 5000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={severity}
                variant="filled"
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
          )}
          <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
            <div className="flex items-center gap-3">
              <Link
                to="/notifications"
                className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11 cursor-pointer"
              >
                <BackSvg />
              </Link>
              <div className="text-xl font-semibold text-gray-800">
                შეტყობინების რედაქტირება
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="h-[calc(100%-77px)] grid lg:grid-cols-[170px_auto_253px] xl:grid-cols-[240px_auto_303px] 2xl:grid-cols-[290px_auto_353px]"
          >
            <ContentTemplate font={font} setFont={setFont} />
            <TextArea
              font={font}
              formData={formData}
              setFormData={setFormData}
              language={language}
              setLanguage={setLanguage}
              errors={errors}
              setErrors={setErrors}
            />
            <GroupUser
              formData={formData}
              setFormData={setFormData}
              language={language}
              errors={errors}
              setErrors={setErrors}
            />
          </form>
        </>
      )}
    </NotificationsLayout>
  );
};

export default EditNotification;
