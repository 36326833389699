import GroupsLayout from "./GroupsLayout";
import GroupsPropertisBar from "../../components/table/groupsNew/GroupsPropertisBar";
import ParcelGroupsTable from "../../components/table/groupsNew/ParcelGroupsTable";

const ParcelGroupsNew = () => {
  return (
    <GroupsLayout>
      <GroupsPropertisBar />
      <ParcelGroupsTable />
    </GroupsLayout>
  );
};

export default ParcelGroupsNew;
