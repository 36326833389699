import {
  AlignCenterSvg,
  AlignLeftSvg,
  AlignRightSvg,
  BoldSvg,
  ItalicSvg,
  LinkSvg,
  LowercaseSvg,
  UnderlineSvg,
  UppercaseSvg,
} from "../icons";
import UploadFile from "./UploadFile";

const fonts = ["Arial", "Georgia", "Times New Roman", "Courier New"];

const TextEditorTools = ({ setFont, font }) => {
  const handleCommand = (command) => {
    document.execCommand(command, false, null);
  };

  // const handleLink = () => {
  //   const url = prompt("Enter the URL");
  //   document.execCommand("createLink", false, url);
  // };

  const handleLink = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();

      if (selectedText.trim()) {
        const url = prompt("Enter the URL");
        if (url) {
          range.deleteContents();

          const linkNode = document.createElement("a");
          const isValidUrl = /^(https?:\/\/)/i.test(url)
            ? url
            : `https://${url}`;

          linkNode.href = isValidUrl;
          linkNode.target = "_blank";
          linkNode.textContent = selectedText;

          linkNode.style.color = "blue";
          linkNode.style.textDecoration = "underline";

          range.insertNode(linkNode);

          selection.removeAllRanges();

          const textArea = document.querySelector("[contenteditable]");
          if (textArea) {
            const inputEvent = new Event("input", { bubbles: true });
            textArea.dispatchEvent(inputEvent);
          }
        }
      } else {
        alert("Please select text to add a link.");
      }
    }
  };

  const handleUppercase = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString().toUpperCase();
      range.deleteContents();
      range.insertNode(document.createTextNode(selectedText));
    }
  };

  const handleLowercase = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString().toLowerCase();
      range.deleteContents();
      range.insertNode(document.createTextNode(selectedText));
    }
  };

  const handleChangeFont = (event) => {
    setFont(event.target.value);
    document.execCommand("fontName", false, event.target.value);
  };

  return (
    <div className="flex flex-col items-start gap-4">
      <select
        onChange={handleChangeFont}
        value={font}
        className="px-4 py-1.5 bg-white border rounded-xl w-full appearance-none outline-none text-sm 2xl:text-base"
      >
        {fonts.map((font) => (
          <option key={font} value={font}>
            {font}
          </option>
        ))}
      </select>
      <div className="w-full grid grid-cols-3 gap-3">
        <button
          type="button"
          onClick={handleUppercase}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <UppercaseSvg />
        </button>
        <button
          type="button"
          onClick={handleLowercase}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <LowercaseSvg />
        </button>
        <button
          type="button"
          onClick={() => handleCommand("italic")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <ItalicSvg />
        </button>
        <button
          type="button"
          onClick={() => handleCommand("justifyLeft")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <AlignLeftSvg />
        </button>
        <button
          type="button"
          onClick={() => handleCommand("justifyCenter")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <AlignCenterSvg />
        </button>
        <button
          type="button"
          onClick={() => handleCommand("justifyRight")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <AlignRightSvg />
        </button>
        <button
          type="button"
          onClick={() => handleCommand("bold")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <BoldSvg />
        </button>
        <button
          type="button"
          onClick={handleLink}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <LinkSvg />
        </button>
        <button
          type="button"
          onClick={() => handleCommand("underline")}
          className="w-full h-auto py-4 rounded-lg flex justify-center items-center border border-gray-200"
        >
          <UnderlineSvg />
        </button>
      </div>
      <UploadFile />
    </div>
  );
};

export default TextEditorTools;
