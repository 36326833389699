import { CloseModalSvg } from "../../icons";

const ModalsHeadline = ({ onClick, text }) => {
  return (
    <div className="w-full flex items-center justify-between p-6 border-b border-gray-200">
      <h2 className="text-xl font-bold text-blue-1 capitalize">{text}</h2>
      <button type="button" onClick={onClick} className="cursor-pointer">
        <CloseModalSvg />
      </button>
    </div>
  );
};

export default ModalsHeadline;
