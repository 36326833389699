import { useState, useEffect, useRef } from "react";
import { ArrowDownSvg, CloseModalSvg } from "../../icons";

const Filter = ({ options, name, value, onChange }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(term)
    );
    setFilteredOptions(filtered);
  };

  const handleSelectChange = (value) => {
    onChange({ target: { name, value } });
    setShowDropdown(false);
    setSearchTerm("");
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  const handleClearValue = (e) => {
    e.stopPropagation();
    onChange({ target: { name, value: "" } });
    setSearchTerm("");
  };
  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className={`relative cursor-pointer mt-1 block w-full text-sm`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <ArrowDownSvg />
      </div>

      {showDropdown && (
        <div className="absolute z-10 mt-1 right-0 w-40 bg-white border border-gray-200 rounded-lg max-h-60 overflow-auto">
          {value && (
            <div className="px-4 py-2 text-xs border-b border-gray-300">
              {value && options.find((option) => option.value === value)?.label}
            </div>
          )}
          <div>
            <input
              type="text"
              className="w-full text-xs px-4 py-2 border-b border-gray-300 outline-none"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />

            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectChange(option.value)}
                  className={`flex justify-between items-center gap-1 cursor-pointer px-4 py-2 text-xs hover:bg-blue-2/10 ${
                    value === option.value ? "bg-blue-2/10 font-semibold" : ""
                  }`}
                >
                  {option.label}
                  {value === option.value && (
                    <span
                      className="w-4 h-4 flex justify-center items-center"
                      onClick={(e) => handleClearValue(e, name)}
                    >
                      <CloseModalSvg />
                    </span>
                  )}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500 text-xs">No options found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
