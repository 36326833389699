import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import Spinner from "../common/Spinner";

const MultiRoomNumbers = ({
  setFormData,
  formData,
  errormark,
  options,
  showOptions,
  setShowOptions,
  isLoading,
  onChange,
  name,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const { t } = useTranslation();

  const handleSelectItem = (item) => {
    if (!selectedItems.includes(item)) {
      setSelectedItems([...selectedItems, item]);
      setFormData((prev) => ({
        ...prev,
        userRoomNumbers: [...selectedItems, item],
      }));
    }

    const inputField = document.querySelector(`input[name="${name}"]`);
    if (inputField) {
      inputField.value = "";
    }

    if (showOptions) {
      setShowOptions(false);
    }
  };

  const handleRemoveItem = (item) => {
    setSelectedItems(selectedItems.filter((selected) => selected !== item));
    setFormData((prev) => ({
      ...prev,
      userRoomNumbers: selectedItems.filter((selected) => selected !== item),
    }));
  };

  return (
    <div className="relative max-w-full w-full">
      <div
        className={`relative w-full rounded-xl overflow-hidden bg-white px-4 py-2 flex flex-col items-start ${
          errormark === "error-border" ? "border-red-500" : "border-gray-200"
        } border`}
      >
        <div className="grid grid-cols-3 gap-x-2">
          {formData.userRoomNumbers?.length > 0 &&
            formData.userRoomNumbers?.map((item, index) => (
              <Tooltip title={item} key={index}>
                <div className="flex justify-between items-center border border-gray-1/20 px-2 py-1 gap-2 rounded-xl text-blue-2 font-medium text-xs mb-2">
                  <span className="w-[calc(100%-20px)] truncate">{item}</span>
                  <span
                    className="cursor-pointer text-gray-1 text-sm !font-bold"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveItem(item);
                    }}
                  >
                    ✕
                  </span>
                </div>
              </Tooltip>
            ))}
        </div>
        <input
          className="w-full outline-none text-gray-1 text-xs py-1 placeholder:text-gray-1"
          name={name}
          placeholder="Room Numbers"
          onChange={onChange}
        />
      </div>

      {showOptions && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-xl shadow-lg max-h-48 overflow-y-auto">
          {isLoading ? (
            <div className="relative w-full h-20">
              <Spinner />
            </div>
          ) : options.length > 0 ? (
            options.map((option, index) => (
              <div
                key={index}
                className={`px-4 py-2 hover:bg-blue-100 cursor-pointer font-medium text-xs ${
                  selectedItems.includes(option)
                    ? "bg-gray-100 text-gray-1/50"
                    : "text-gray-1"
                }`}
                onClick={() => handleSelectItem(option)}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
              {t("text.not_found")}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiRoomNumbers;
