import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";

const Tools = ({ details, language }) => {
  const notificationDetails = details.notificationDetails || {};
  const title = notificationDetails[language]?.title || "";

  return (
    <TabGroup className="px-5 py-3 border-l border-black/10 flex flex-col justify-between">
      <div>
        <TabList className="grid grid-cols-[auto_auto] mt-10 lg:mt-0">
          <Tab className="border-transparent border-b-4 data-[selected]:border-blue-2 p-2 outline-none text-sm 2xl:text-base 2xl:p-4">
            Group
          </Tab>
          <Tab className="border-transparent border-b-4 data-[selected]:border-blue-2 p-2 outline-none text-sm 2xl:text-base 2xl:p-4">
            One User
          </Tab>
        </TabList>
        <TabPanels>
          <div className="mt-6 flex flex-col items-start gap-2 w-full">
            <span className="text-gray-1 text-xs font-normal">Title</span>
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 text-gray-1 text-sm font-normal px-4 py-2">
              {title}
            </div>
          </div>
          <TabPanel className="py-6 w-full flex flex-col items-start gap-6">
            <div className="flex flex-col items-start gap-2 w-full">
              <span className="text-gray-1 text-xs font-normal">Sent to</span>
              <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 text-gray-1 text-sm font-normal px-4 py-2 flex items-center justify-start flex-wrap">
                {details && details?.parcelGroupBarcodes?.length > 0 ? (
                  details?.parcelGroupBarcodes?.map((item) => (
                    <span className="p-1">{item};</span>
                  ))
                ) : (
                  <>There are no groups</>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 w-full">
              <span className="text-gray-1 text-xs font-normal">
                Notification Type
              </span>
              <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 text-gray-1 text-sm font-normal px-4 py-2 flex items-center justify-start flex-wrap">
                {details && details?.notificationTypes?.length > 0 ? (
                  details?.notificationTypes?.map((item) => (
                    <span className="p-1">{item};</span>
                  ))
                ) : (
                  <>There are no types</>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 w-full">
              <span className="text-gray-1 text-xs font-normal">Recipient</span>
              <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 text-gray-1 text-sm font-normal px-4 py-2 flex items-center justify-start flex-wrap">
                {details && details?.notificationCustomerTypes?.length > 0 ? (
                  details?.notificationCustomerTypes?.map((item) => (
                    <span className="p-1">{item};</span>
                  ))
                ) : (
                  <>There are no Recipient</>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel className="py-6 w-full flex flex-col items-start gap-6">
            <div className="flex flex-col items-start gap-2 w-full">
              <span className="text-gray-1 text-xs font-normal">
                Room Numbers
              </span>
              <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 text-gray-1 text-sm font-normal px-4 py-2 flex items-center justify-start flex-wrap">
                {details && details?.userRoomNumbers?.length > 0 ? (
                  details?.userRoomNumbers?.map((item) => (
                    <span className="p-1">{item};</span>
                  ))
                ) : (
                  <>There are no room Numbers</>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 w-full">
              <span className="text-gray-1 text-xs font-normal">Numbers</span>
              <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 text-gray-1 text-sm font-normal px-4 py-2 flex items-center justify-start flex-wrap">
                {details && details?.mobiles?.length > 0 ? (
                  details?.mobiles?.map((item) => (
                    <span className="p-1">{item};</span>
                  ))
                ) : (
                  <>There are no numbers</>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 w-full">
              <span className="text-gray-1 text-xs font-normal">Emails</span>
              <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 text-gray-1 text-sm font-normal px-4 py-2 flex items-center justify-start flex-wrap">
                {details && details?.emails?.length > 0 ? (
                  details?.emails?.map((item) => (
                    <span className="p-1">{item};</span>
                  ))
                ) : (
                  <>There are no emails</>
                )}
              </div>
            </div>
          </TabPanel>
          <div className="flex flex-col items-start gap-2 w-full">
            <span className="text-gray-1 text-xs font-normal">
              Scheduled Notifictaion
            </span>
            <div className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 text-gray-1 text-sm font-normal px-4 py-2">
              {details && details?.scheduleDate !== null ? (
                <span className="p-1">{details?.scheduleDate};</span>
              ) : (
                <>There is no scheduled</>
              )}
            </div>
          </div>
        </TabPanels>
      </div>
    </TabGroup>
  );
};

export default Tools;
