import { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";

const DatePickerComponent = ({ value, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(value || null);

  return (
    <Datepicker
      asSingle={true}
      value={selectedDate}
      onChange={(newValue) => {
        const startDate = newValue.startDate;

        const formattedStartDate = startDate
          ? startDate.toISOString().split("T")[0]
          : "";

        setSelectedDate(newValue);

        onChange(formattedStartDate);
      }}
    />
  );
};

export default DatePickerComponent;
