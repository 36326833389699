import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationDetails } from "../../features/notifications/notifications/NotificationsSlice";
import { Link, useParams } from "react-router-dom";
import { BackSvg } from "../../components/icons";
import NotificationsLayout from "./NotificationsLayout";
import Main from "../../components/notifications/details/Main";
import Tools from "../../components/notifications/details/Tools";

const DetailsNotification = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { details, isLoading } = useSelector(
    (state) => state.notificationsList
  );
  const [language, setLanguage] = useState("GE");

  useEffect(() => {
    dispatch(getNotificationDetails({ notificationId: id }));
  }, [dispatch, id]);

  return (
    <NotificationsLayout>
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to="/notifications"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11 cursor-pointer"
          >
            <BackSvg />
          </Link>
          <div className="text-xl font-semibold text-gray-800">
            შეტყობინების დეტალები
          </div>
        </div>
      </div>
      <div className="md:grid lg:grid-cols-[auto_265px] xl:grid-cols-[auto_350px]">
        <Main
          details={details}
          isLoading={isLoading}
          language={language}
          setLanguage={setLanguage}
        />
        <Tools details={details} language={language} />
      </div>
    </NotificationsLayout>
  );
};

export default DetailsNotification;
