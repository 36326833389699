import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchUser, reset } from "../../../features/groups/UsersSlice";
import Input from "../../common/Input";
import Spinner from "../../common/Spinner";

const SearchUser = ({
  name,
  setFormData,
  error,
  errormark,
  setErrors,
  roomNumber,
  clear,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [showUsers, setShowUsers] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const debounceTimeout = useRef(null);

  const dispatch = useDispatch();
  const { users, isLoading } = useSelector((state) => state.roomUsers);

  useEffect(() => {
    if (clear) {
      setInputValue("");
      setSelectedLabel("");
      setShowUsers(false);
    }
  }, [clear]);

  useEffect(() => {
    if (roomNumber) {
      dispatch(searchUser(roomNumber));
    }
  }, [roomNumber, dispatch]);

  useEffect(() => {
    if (roomNumber) {
      const user = users.find((user) => user.roomNumber === roomNumber);
      if (user) {
        setSelectedLabel(user.roomNumber);
        setFormData((prevFormData) => ({
          ...prevFormData,
          userId: user.id,
        }));
        setShowUsers(false);
      }
    }
  }, [roomNumber, users, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputValue(value);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (selectedLabel) {
      setSelectedLabel("");
    }

    if (value.trim() === "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: "",
      }));
      setShowUsers(false);
      return;
    }

    if (value.length > 2) {
      dispatch(reset());
      setShowUsers(true);

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        dispatch(searchUser(value));
      }, 500);
    } else {
      setShowUsers(false);
    }
  };

  const handleUserSelect = (userId, userLabel) => {
    if (userLabel) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: userId,
      }));
    }

    setSelectedLabel(userLabel);
    setInputValue("");
    setShowUsers(false);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
    };
  }, []);

  return (
    <div className="relative">
      <Input
        text="User"
        name={name}
        value={selectedLabel || inputValue}
        onchange={handleInputChange}
        error={error}
        errormark={errormark}
      />
      {showUsers && (
        <div className="absolute z-20 w-full max-h-lg overflow-auto rounded-xl bg-white border border-gray-200">
          {isLoading ? (
            <div className="p-2 text-gray-500 h-40">
              <Spinner />
            </div>
          ) : users?.length > 0 ? (
            users?.map((user) => (
              <div
                key={user.id}
                onClick={() => handleUserSelect(user.id, user.roomNumber)}
                className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
              >
                {user.label}
              </div>
            ))
          ) : (
            <div className="px-4 py-10 w-full h-40 text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
              No users found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchUser;
