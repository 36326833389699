import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import fileService from "./FilesService";

const initialState = {
  file: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const uploadFile = createAsyncThunk(
  "notification/upload_file",
  async (file, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await fileService.uploadFile(token, file);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const uploadFileSlice = createSlice({
  name: "notificationFile",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.file = action.payload;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = uploadFileSlice.actions;
export default uploadFileSlice.reducer;
