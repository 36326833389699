import Spinner from "../../common/Spinner";

const Main = ({ details, language, setLanguage, isLoading }) => {
  const handleSwitchLanguage = (lang) => {
    setLanguage(lang);
  };

  const notificationDetails = details.notificationDetails || {};
  const message = notificationDetails[language]?.message || "";
  const title = notificationDetails[language]?.title || "";

  const isHTML = (content) => /<\/?[a-z][\s\S]*>/i.test(content);

  const extractImageUrl = (content) => {
    const match = content.match(/<img[^>]+src="([^">]+)"/);
    return match ? match[1] : null;
  };

  const imageUrl = extractImageUrl(message);

  const removeImageTags = (content) => {
    return content.replace(/<img[^>]*>/g, "");
  };

  const cleanMessage = isHTML(message) ? removeImageTags(message) : message;

  return (
    <div className="px-5 pb-4">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="max-w-80 w-full mx-auto my-0 grid grid-cols-2 px-5 mb-6">
            <button
              type="button"
              onClick={() => handleSwitchLanguage("GE")}
              className={`py-3 border-b-4 ${
                language === "GE" ? "border-blue-2" : "border-transparent"
              } text-sm 2xl:text-base`}
            >
              Georgian
            </button>
            <button
              type="button"
              onClick={() => handleSwitchLanguage("EN")}
              className={`py-3 border-b-4 ${
                language === "EN" ? "border-blue-2" : "border-transparent"
              } text-sm 2xl:text-base`}
            >
              English
            </button>
          </div>
          <div className="bg-blue-7 p-8 rounded-xl flex flex-col items-center gap-4">
            <div className="w-full flex flex-col items-center gap-4 py-8">
              <img
                className="max-w-20 w-full"
                src="https://middleware.spacecargo.ge/email/logo.png"
                alt="space cargo"
              />
              <h1 className="text-[26px] font-light text-black">{title}</h1>
            </div>

            <div className="details bg-white rounded-xl py-8 px-5 text-center w-full flex flex-col items-center">
              <img
                className="max-w-64 w-full"
                src={
                  imageUrl ||
                  "https://middleware.spacecargo.ge/email/from-space-cargo.png"
                }
                alt="response"
              />
              {isHTML(cleanMessage) ? (
                <p
                  className="pt-5 border-t border-black/5 text-black/70 max-w-full w-full mx-auto mt-7 md:px-6"
                  dangerouslySetInnerHTML={{ __html: cleanMessage }}
                ></p>
              ) : (
                <p className="pt-5 border-t border-black/5 text-black/70 max-w-full w-full mx-auto mt-7 md:px-6">
                  {cleanMessage}
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Main;
