import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotTypes } from "../../features/notifications/notTypes/NotTypeSlice";
import { getNotCustomerTypes } from "../../features/notifications/notCustomerTypes/notCustomerTypesSlice";
import {
  getParcelGroups,
  reset,
} from "../../features/notifications/parcelGroups/ParcelGroupsSlice";
import Checkbox from "./Checkbox";
import MultiSelect from "./MultiSelect";

const Group = ({ formData, setFormData, errors, setErrors }) => {
  const [showGroups, setShowGroups] = useState(false);
  const dispatch = useDispatch();
  const debounceTimeout = useRef(null);
  const { notificationTypes } = useSelector((state) => state.notificationTypes);
  const { notificationCustomerTypes } = useSelector(
    (state) => state.notificationCustomerTypes
  );
  const { notificationParcelGroups, isLoading } = useSelector(
    (state) => state.notificationsParcelGroups
  );

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    if (name === "groups" && value.length > 2) {
      await dispatch(reset());
      setShowGroups(true);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        dispatch(getParcelGroups(value));
      }, 500);
    } else {
      setShowGroups(false);
    }
  };

  useEffect(() => {
    dispatch(getNotTypes());
    dispatch(getNotCustomerTypes());
  }, [dispatch]);

  const handleCheckboxChange = (desc, type) => {
    setFormData((prevFormData) => {
      const arrayToUpdate = prevFormData[type] || [];
      const updatedArray = arrayToUpdate.includes(desc)
        ? arrayToUpdate.filter((item) => item !== desc)
        : [...arrayToUpdate, desc];

      setErrors((prevErrors) => ({
        ...prevErrors,
        [type]: updatedArray.length > 0 ? [] : prevErrors[type],
      }));

      return {
        ...prevFormData,
        [type]: updatedArray,
      };
    });
  };

  const handleOnlyDeclaredChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      onlyDeclared: e.target.checked ? "Y" : "N",
    }));
  };

  const options = notificationParcelGroups?.map((group) => group.barcode) || [];

  return (
    <div className="w-full flex flex-col items-start gap-6">
      <MultiSelect
        options={options}
        showOptions={showGroups}
        setShowOptions={setShowGroups}
        isLoading={isLoading}
        name="groups"
        onChange={handleInputChange}
        setFormData={setFormData}
        formData={formData}
      />
      <div className="flex justify-start items-center gap-2">
        <Checkbox
          checked={formData.onlyDeclared === "Y"}
          onChange={handleOnlyDeclaredChange}
        />
        <span className="text-xs text-gray-1">მხოლოდ დასადეკლარირებელი</span>
      </div>
      <div>
        <span className="text-xs text-gray-1">Notifications Type</span>
        <ul className="py-3 w-full flex flex-col items-start gap-3">
          {notificationTypes &&
            notificationTypes?.map((type, index) => (
              <li
                key={index}
                className="flex justify-start items-center gap-2 text-xs text-gray-1"
              >
                <Checkbox
                  checked={formData.notificationTypes?.includes(type.name)}
                  onChange={() =>
                    handleCheckboxChange(type.name, "notificationTypes")
                  }
                />
                {type.desc}
              </li>
            ))}
        </ul>
        {errors.notificationTypes.length > 0 && (
          <p className="text-red-500 text-xs mt-1">
            {errors.notificationTypes[0]}
          </p>
        )}
      </div>
      <div>
        <span className="text-xs text-gray-1">Recipient</span>
        <ul className="py-3 w-full flex flex-col items-start gap-3">
          {notificationCustomerTypes &&
            notificationCustomerTypes?.map((type, index) => (
              <li
                key={index}
                className="flex justify-start items-center gap-2 text-xs text-gray-1"
              >
                <Checkbox
                  checked={formData.notificationCustomerTypes?.includes(
                    type.name
                  )}
                  onChange={() =>
                    handleCheckboxChange(type.name, "notificationCustomerTypes")
                  }
                />
                {type.desc}
              </li>
            ))}
        </ul>
        {/* {errors.notificationCustomerTypes.length > 0 && (
          <p className="text-red-500 text-xs mt-1">
            {errors.notificationCustomerTypes[0]}
          </p>
        )} */}
      </div>
    </div>
  );
};

export default Group;
