import React, { useState } from "react";

const GroupsLayout = ({ children }) => {
  const [parcelGroupId, setParcelGroupId] = useState("id");
  console.log("this is comes from lyauot");
  return (
    <div>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, { parcelGroupId, setParcelGroupId })
          : child
      )}
    </div>
  );
};

export default GroupsLayout;
