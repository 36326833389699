import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import notificationsService from "./NotificationsService";

const initialState = {
  notifications: [],
  details: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
};

export const getNotifications = createAsyncThunk(
  "notification/get_notifications",
  async (
    { title, recipientType, status, notificationType, page, perPage },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await notificationsService.getNotifications(
        token,
        title,
        recipientType,
        status,
        notificationType,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const getNotificationDetails = createAsyncThunk(
  "notification/get_notification_details",
  async ({ notificationId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await notificationsService.getNotificationDetails(
        token,
        notificationId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const addNotification = createAsyncThunk(
  "notification/add_notification",
  async (notificationData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await notificationsService.addNotification(
        notificationData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const editNotification = createAsyncThunk(
  "notification/edit_notification",
  async (notificationData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await notificationsService.editNotification(
        notificationData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/delete_notification",
  async (notificationId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await notificationsService.deleteNotification(
        notificationId,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notifications = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getNotificationDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotificationDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.details = action.payload;
      })
      .addCase(getNotificationDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.notifications?.notifications?.unshift(action.payload);
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.notifications?.notifications?.findIndex(
          (notification) => notification.id === action.payload.id
        );
        if (index !== -1) {
          state.notifications.notifications[index] = {
            ...state.notifications[index],
            ...action.payload,
          };
        }
      })
      .addCase(editNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.notifications = state.notifications?.notifications?.filter(
          (notification) => notification.id !== action.payload.id
        );
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = notificationsSlice.actions;
export default notificationsSlice.reducer;
