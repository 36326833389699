// import { useState, useRef, useEffect } from "react";
// import Datepicker from "react-tailwindcss-datepicker";
// import { IoCalendarOutline } from "react-icons/io5";

// const DatePickerComponent = ({ value, onChange }) => {
//   const [activePicker, setActivePicker] = useState(false);
//   const datePickerRef = useRef(null);

//   useEffect(() => {
//     const handleOutsideClick = (e) => {
//       if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
//         setActivePicker(false);
//       }
//     };

//     document.addEventListener("click", handleOutsideClick);
//     return () => {
//       document.removeEventListener("click", handleOutsideClick);
//     };
//   }, []);

//   const handleActivePicker = () => {
//     setActivePicker(!activePicker);
//   };

//   return (
//     <div className="inline-block" ref={datePickerRef}>
//       <div onClick={handleActivePicker} className="cursor-pointer">
//         <IoCalendarOutline className="text-blue-4/60 text-lg" />
//       </div>

//       {activePicker && (
//         <div
//           className={`absolute w-full right-0 top-1/2 transform -translate-y-1/2 z-50 transition-all  duration-500 "w-full`}
//         >
//           <Datepicker
//             value={value}
//             onChange={onChange}
//             showShortcuts={true}
//             popperClassName="z-50"
//             popperPlacement="bottom-start"
//             portalId="root"
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DatePickerComponent;

import { useState, useRef, useEffect } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { IoCalendarOutline } from "react-icons/io5";

const DatePickerComponent = ({ value, onChange }) => {
  const [activePicker, setActivePicker] = useState(false);
  const [selectedDates, setSelectedDates] = useState(
    value || { startDate: null, endDate: null }
  );
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (datePickerRef.current && !datePickerRef.current.contains(e.target)) {
        setActivePicker(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleActivePicker = () => {
    setActivePicker(!activePicker);
  };

  const handleDateChange = (newDate) => {
    setSelectedDates(newDate);
    onChange(newDate);

    if (newDate.startDate && newDate.endDate) {
      setActivePicker(false);
    }
  };

  return (
    <div className="inline-block" ref={datePickerRef}>
      <div onClick={handleActivePicker} className="cursor-pointer">
        <IoCalendarOutline className="text-blue-4/60 text-lg" />
      </div>

      {activePicker && (
        <div
          className={`absolute w-full right-0 top-1/2 transform -translate-y-1/2 z-50 transition-all duration-500`}
        >
          <Datepicker
            value={selectedDates}
            onChange={handleDateChange}
            showShortcuts={true}
            popperClassName="z-50"
            popperPlacement="bottom-start"
            portalId="root"
          />
        </div>
      )}
    </div>
  );
};

export default DatePickerComponent;
