import { renderRoutes } from "./generate-routes";

import AnonymousLayout from "../layouts/AnonymousLayout";
import MainLayout from "../layouts/MainLayout";

import SignIn from "../pages/SignIn";
import Projects from "../pages/Projects";
import Invoice from "../pages/Invoice";
import FileCategories from "../pages/FileCategories";
import Departments from "../pages/Departments";
import Delegation from "../pages/Delegation";
import Clients from "../pages/Clients";
import Companies from "../pages/Companies";
import Categories from "../pages/Categories";
import GroupsList from "../pages/Groups/GroupsList";
import GroupParcels from "../pages/Groups/GroupParcels";
import ParcelDetails from "../pages/Groups/ParcelDetails";
import ResponseTimeDetails from "../pages/Dashboard/ResponseTime";
import MainPage from "../pages/Dashboard/MainPage";
import DeliveryTimeDetails from "../pages/Dashboard/DeliveryTime";
import SatisfactionScoreDetails from "../pages/Dashboard/SatisfactionScore";
import RacesProgressDetails from "../pages/Dashboard/RacesProgress";
import IssuedParcelsDetails from "../pages/Dashboard/IssuedParcels";
import Profile from "../pages/Profile";
import Notifications from "../pages/Notifications";
import CreateNotification from "../pages/Notifications/CreateNotification";
import EditNotification from "../pages/Notifications/EditNotification";
import DetailsNotification from "../pages/Notifications/DetailsNotification";
import ParcelBoxes from "../pages/ParcelBoxes";
import StandartPrices from "../pages/StandartPrices";
import UserPrices from "../pages/UserPrices";
import ParcelGroupsNew from "../pages/ParcelGroupsNew";
import ParcelsNew from "../pages/ParcelGroupsNew/Parcels";
import AddParcel from "../pages/ParcelGroupsNew/AddParcel";
import EditParcel from "../pages/ParcelGroupsNew/EditParcel";

export const routes = [
  {
    layout: AnonymousLayout,
    routes: [
      {
        name: "signin",
        title: "SignIn page",
        component: SignIn,
        path: "/",
        isPublic: true,
      },
    ],
  },
  {
    layout: MainLayout,
    routes: [
      {
        name: "dashboard",
        title: "Dashboard main page",
        component: MainPage,
        path: "/MainPage",
      },
      {
        name: "projects",
        title: "Projects page",
        component: Projects,
        path: "/projects",
      },
      {
        name: "invoice",
        title: "Invoice page",
        component: Invoice,
        path: "/invoice",
      },
      {
        name: "filecategories",
        title: "File Categories",
        component: FileCategories,
        path: "/delegationFileCategory",
      },
      {
        name: "departments",
        title: "Departments",
        component: Departments,
        path: "/delegationDepartments",
      },
      {
        name: "delegation",
        title: "Delegation",
        component: Delegation,
        path: "/delegation",
      },
      {
        name: "clients",
        title: "Clients",
        component: Clients,
        path: "/clients",
      },
      {
        name: "categories",
        title: "Categories",
        component: Categories,
        path: "/delegationCategory",
      },
      {
        name: "companies",
        title: "Companies",
        component: Companies,
        path: "/CompanyData",
      },
      {
        name: "groupList",
        title: "List",
        component: GroupsList,
        path: "/ParcelGroup",
      },
      {
        name: "parcels",
        title: "Parcels",
        component: GroupParcels,
        path: "/ParcelGroup/:id",
      },
      {
        name: "parcels",
        title: "Parcels",
        component: ParcelDetails,
        path: "/ParcelGroup/:id/:id",
      },
      {
        name: "responseTimeDetails",
        title: "responseTimeDetails",
        component: ResponseTimeDetails,
        path: "/responseTimeDetails",
      },
      {
        name: "deliveryTimeDetails",
        title: "deliveryTimeDetails",
        component: DeliveryTimeDetails,
        path: "/deliveryTimeDetails",
      },
      {
        name: "satisfactionScoreDetails",
        title: "satisfactionScoreDetails",
        component: SatisfactionScoreDetails,
        path: "/satisfactionScoreDetails",
      },
      {
        name: "racesProgressDetailsWithId",
        title: "racesProgressDetails",
        component: RacesProgressDetails,
        path: "/racesProgress/:id",
      },
      {
        name: "racesProgressDetailsWithoutId",
        title: "racesProgressDetails",
        component: RacesProgressDetails,
        path: "/racesProgress/",
      },
      {
        name: "issuedParcelsDetails",
        title: "issuedParcelsDetails",
        component: IssuedParcelsDetails,
        path: "/issuedParcelsDetails",
      },
      {
        name: "profile",
        title: "profile",
        component: Profile,
        path: "/profile",
      },

      {
        name: "notifications",
        title: "Notifications List",
        component: Notifications,
        path: "/notifications",
      },
      {
        name: "createNotification",
        title: "Create Notification",
        component: CreateNotification,
        path: "/notifications/create",
      },
      {
        name: "editNotification",
        title: "Edit Notification",
        component: EditNotification,
        path: "/notifications/edit/:id",
      },
      {
        name: "detailsNotification",
        title: "Details Notification",
        component: DetailsNotification,
        path: "/notifications/details/:id",
      },
      {
        name: "parcelBoxes",
        title: "Parcel Boxes",
        component: ParcelBoxes,
        path: "/parcelBoxes",
      },
      {
        name: "standartPrices",
        title: "Standart Prices",
        component: StandartPrices,
        path: "/StandartPrices",
      },
      {
        name: "userPrices",
        title: "User Prices",
        component: UserPrices,
        path: "/userPrices",
      },
      {
        name: "parcelGroupsNew",
        title: "Parcel Groups New",
        component: ParcelGroupsNew,
        path: "/ParcelGroupNew",
      },
      {
        name: "parcelsNew",
        title: "Parcels New",
        component: ParcelsNew,
        path: "/ParcelGroupNew/:id",
      },
      {
        name: "addParcel",
        title: "Add Parcel",
        component: AddParcel,
        path: "/ParcelGroupNew/add",
      },
      {
        name: "editParcel",
        title: "Edit Parcel",
        component: EditParcel,
        path: "/ParcelGroupNew/edit",
      },
    ],
  },
];

export const Routes = renderRoutes(routes);
