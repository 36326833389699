import Datepicker from "react-tailwindcss-datepicker";

const DatePickerFilter = ({ text, name, onChange, value, error }) => {
  const handleChange = (date) => {
    onChange({
      target: {
        name,
        value: date.startDate || "",
      },
    });
  };
  return (
    <div className="flex justify-center items-center">
      <Datepicker
        asSingle
        value={{ startDate: value, endDate: value }}
        onChange={handleChange}
        inputClassName={`w-full px-4 py-4 border ${
          error ? "border-red-500" : "border-gray-200 "
        } rounded-lg placeholder-gray-1 text-sm focus:outline-none focus:ring focus:ring-blue-2/50`}
        placeholder={text}
        useRange={false}
      />
    </div>
  );
};

export default DatePickerFilter;
