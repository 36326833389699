import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import recipientTypesService from "./RecipientTypesService";

const initialState = {
  recipientTypes: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getRecipientTypes = createAsyncThunk(
  "notification/get_recipient_types",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await recipientTypesService.getRecipientTypes(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const recipientTypesSlice = createSlice({
  name: "recipient types",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecipientTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecipientTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.recipientTypes = action.payload;
      })
      .addCase(getRecipientTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = recipientTypesSlice.actions;
export default recipientTypesSlice.reducer;
