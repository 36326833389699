import Datepicker from "react-tailwindcss-datepicker";

const SingleDatePicker = ({ text, name, onChange, value, error }) => {
  const handleChange = (date) => {
    if (date) {
      onChange({
        target: {
          name,
          value: date?.startDate || "",
        },
      });
    }
  };

  return (
    <div className="flex justify-center items-center">
      <Datepicker
        key={value || "empty"}
        asSingle
        value={value ? { startDate: value, endDate: value } : null}
        onChange={handleChange}
        inputClassName={`w-full px-4 py-4 border ${
          error ? "border-red-500" : "border-gray-200"
        } rounded-lg placeholder-gray-1 text-sm focus:outline-none focus:ring focus:ring-blue-2/50`}
        placeholder={text}
        useRange={false}
      />
    </div>
  );
};

export default SingleDatePicker;
