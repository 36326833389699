import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import individualPricesService from "./IndividualPricesService";

const initialState = {
  individualPrices: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getIndividualPrices = createAsyncThunk(
  "parcel/get_user_prices",
  async (
    {
      roomNumber,
      fromCountryId,
      toCountryId,
      fromDate,
      toDate,
      price,
      transportationTypeId,
      page,
      perPage,
    },
    thunkAPI
  ) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await individualPricesService.getIndividualPrices(
        token,
        roomNumber,
        fromCountryId,
        toCountryId,
        fromDate,
        toDate,
        price,
        transportationTypeId,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const addIndividualPrice = createAsyncThunk(
  "parcel/add_user_price",
  async (individualPriceData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await individualPricesService.addIndividualPrice(
        individualPriceData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const editIndividualPrice = createAsyncThunk(
  "parcel/edit_user_price",
  async (individualPriceData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await individualPricesService.editIndividualPrice(
        individualPriceData,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const deleteIndividualPrice = createAsyncThunk(
  "parcel/delete_user_price",
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await individualPricesService.deleteIndividualPrice(id, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const individualPricesSlice = createSlice({
  name: "individualPrices",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIndividualPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIndividualPrices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.individualPrices = action.payload;
      })
      .addCase(getIndividualPrices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addIndividualPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addIndividualPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.individualPrices?.userPrices.unshift(action.payload);
      })
      .addCase(addIndividualPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editIndividualPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editIndividualPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.individualPrices?.userPrices?.findIndex(
          (price) => price.id === action.payload.id
        );
        if (index !== -1) {
          state.individualPrices.userPrices[index] = {
            ...state.userPrices[index],
            ...action.payload,
          };
        }
      })
      .addCase(editIndividualPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deleteIndividualPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteIndividualPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        state.individualPrices = state.individualPrices?.userPrices?.filter(
          (price) => price.id !== action.payload.id
        );
      })
      .addCase(deleteIndividualPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      });
  },
});

export const { reset } = individualPricesSlice.actions;
export default individualPricesSlice.reducer;
